@font-face {
	font-family: 'SUIT';
	font-weight: 100;
	font-display : swap ;
	src: local('SUIT-Thin') url('./assets/fonts/SUIT-Thin.woff2') format('woff2'), url('./assets/fonts/SUIT-Thin.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 200;
	font-display : swap ;
	src: local('SUIT-ExtraLight') url('./assets/fonts/SUIT-ExtraLight.woff2') format('woff2'), url('./assets/fonts/SUIT-ExtraLight.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 300;
	font-display : swap ;
	src: local('SUIT-Light') url('./assets/fonts/SUIT-Light.woff2') format('woff2'), url('./assets/fonts/SUIT-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 400;
	font-display : swap ;
	src: local('SUIT-Regular') url('./assets/fonts/SUIT-Regular.woff2') format('woff2'), url('./assets/fonts/SUIT-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 500;
	font-display : swap ;
	src: local('SUIT-Medium') url('./assets/fonts/SUIT-Medium.woff2') format('woff2'), url('./assets/fonts/SUIT-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 600;
	font-display : swap ;
	src: local('SUIT-SemiBold') url('./assets/fonts/SUIT-SemiBold.woff2') format('woff2'), url('./assets/fonts/SUIT-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 700;
	font-display : swap ;
	src: local('SUIT-Bold') url('./assets/fonts/SUIT-Bold.woff2') format('woff2'), url('./assets/fonts/SUIT-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 800;
	font-display : swap ;
	src: local('SUIT-ExtraBold') url('./assets/fonts/SUIT-ExtraBold.woff2') format('woff2'), url('./assets/fonts/SUIT-ExtraBold.ttf') format('truetype');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 900;
	font-display : swap ;
	src: local('SUIT-Heavy') url('./assets/fonts/SUIT-Heavy.woff2') format('woff2'), url('./assets/fonts/SUIT-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Ballet Harmony';
	font-display : swap ;
  src: local('Ballet Harmony'), url(./assets/fonts/Ballet_Harmony.ttf);
}