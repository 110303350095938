.App {
  text-align: center;
  font-family : 'SUIT';
  font-size : 16px;

  /* 스크롤 숨기기 */
  /* 인터넷익스플로러 */
  /* -ms-overflow-style: none;  */
  /* 파이어폭스 */
  /* scrollbar-width: none;  */

  overflow-x: hidden;;

  /* 텍스트 선택 금지 */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* 인터넷익스플로러 */
  user-select: none;
}
